import React from 'react';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import CaseDetails from './components/CaseDetails/CaseDetails';
import CaseListing from './components/CaseListing/CaseListing';
import DocumentDetails from './components/DocumentDetails/DocumentDetails';
import MessageList from './components/Message/MessageList';
import Chat from './components/Message/Chat';
import Survey from './components/Survey/Survey';
import SurveyList from './components/Survey/SurveyList';
import News from './components/News/News';
import Profile from './components/Profile/Profile';
import NotFound from './components/NotFound';
import LoginRedirect from './components/Login/LoginRedirect';
import OnBoarding from "./components/OnBoarding";
import PrivareRoute from './privateRoutes';
import PublicSurvey from './components/Survey/PublicSurvey';
import PublicSurveySuccess from './components/Survey/PublicSurveySuccess';
import Wizard from "./components/Booking/Wizard";
import BookingWizard from "./components/Booking/BookingWizard";
import BankIdLoginRedirect from './components/Login/BankIdLoginRedirect';
import SurveyCallback from './components/Survey/SurveyCallback';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";

const Routes = () => (
	<Router >
		<Switch>
			<Route exact path="/">
				<Login />
			</Route>
			<Route path="/login-redirect">
				<LoginRedirect />
			</Route>
			<Route path="/bankid-login-redirect">
				<BankIdLoginRedirect />
			</Route>
			<Route path="/PublicSurvey/:id">
				<PublicSurvey/>
			</Route>
			<Route path="/PublicSurveySuccess">
				<PublicSurveySuccess/>
			</Route>
			<Route path="/surveycallback">
				<SurveyCallback/>
			</Route>
			<PrivareRoute component={OnBoarding} path="/OnBoarding" />
			<PrivareRoute component={Wizard} path="/Booking" />
			<PrivareRoute component={BookingWizard} path="/Inquiry" />
			<PrivareRoute component={Dashboard} path="/Dashboard" />
			<PrivareRoute component={CaseListing} path="/CaseList" />
			<PrivareRoute component={CaseDetails} path="/CaseDetails" />
			<PrivareRoute component={DocumentDetails} path="/DocumentDetails" />
			<PrivareRoute component={MessageList} path="/ThreadDetails" />
			<PrivareRoute component={Chat} path="/Chat" />
			<PrivareRoute component={Survey} path="/Survey" />
			<PrivareRoute component={SurveyList} path="/SurveyList" />
			<PrivareRoute component={News} path="/News" />
			<PrivareRoute component={Profile} path="/Profile" />
			<Route path="/*">
				<NotFound />
			</Route>
		</Switch>
	</Router>
);

export default Routes;
